import { dew as _ActionInterfaceDew } from "./ActionInterface";
import { dew as _ActionImplDew } from "./ActionImpl";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });
  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  __exportStar(_ActionInterfaceDew(), exports);
  __exportStar(_ActionImplDew(), exports);
  return exports;
}