var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.VisualState = void 0;
  var VisualState;
  (function (VisualState) {
    VisualState["animatingIn"] = "animating-in";
    VisualState["showing"] = "showing";
    VisualState["animatingOut"] = "animating-out";
    VisualState["hidden"] = "hidden";
  })(VisualState = exports.VisualState || (exports.VisualState = {}));
  return exports;
}